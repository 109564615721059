<template>
  <div class=".gg-container-1">
    <div class="search-container">
      <!--输入框-->

      <div
        style="margin-left: auto; display: flex; justify-content: space-around"
      >
        <div class="search-container-fn-input" v-if="operateList.indexOf('add') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-plus"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
            @click="handleAdd"
            >新增班次
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('update') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-plus"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
            v-if="multipleSelection.length == 1"
            @click="handleEdit"
            >编辑班次
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('delete') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-plus"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
            v-if="multipleSelection.length == 1"
            @click="handleDel"
            >删除班次
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('update_table') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
            @click="handleEditTableHead"
            >编辑表头
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('reset_query') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
            @click="handleResetSearch"
            >重置查找
          </el-button>
        </div>
      </div>
    </div>
    <el-table
      border
      size="mini"
      v-loading="listLoading"
      :header-cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      height="725"
      @selection-change="handleSelectionChange"
      style="width: 100%;z-index:0"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column type="index" align="center" width="50">
      </el-table-column>
      <template v-for="(column, index) in tableHead">
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-if="column.field_type === 'textBtn' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a
              style="font-size: 12px; color: #2379fb"
              @click.prevent="handleCustomerDetail(scope.row)"
            >
              {{ scope.row[column.column_prop] }}
            </a>
          </template>
        </el-table-column>
        <el-table-column
          :sortable="tableHeadSortable.includes(column.column_prop)"
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.visible === true"
          show-overflow-tooltip
        >
          <template #header>
            <el-popover
              placement="bottom"
              title=""
              min-width="160"
              trigger="click"
              v-if="
                searchTableHead.filter(
                  (item) => item.title == column.column_prop
                ).length > 0
              "
            >
              <span slot="reference" class="search-header">
                <span class="search-title">{{
                  column.column_label_user_definition
                    ? column.column_label_user_definition
                    : column.column_label
                }}</span>
                <i
                  style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                  class="el-icon-search"
                  :class="{
                    isSearch: searchTableHead.filter(
                      (item) => item.title == column.column_prop
                    )[0].isSearch,
                  }"
                />
              </span>
            </el-popover>
            <span v-else>{{
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <pagination
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <el-drawer
      title=""
      :visible.sync="drawerVisible"
      direction="rtl"
      v-loading="listLoading"
      :before-close="handleClosePres"
      @closeDrawer="handleClosePres"
      size="60%"
      :with-header="false"
    >
      <elDrawerAdd
        @closeDrawer="handleClosePres"
        @getList="getList"
        :info="info"
        v-if="drawerVisible"
      />
    </el-drawer>
    <!--编辑表头-->
    <shiftManagementTableHead
      :isActivePhotoDrugTableHead.sync="isActivePhotoDrugTableHead"
      @_getSchedusList="_getSchedusList"
      v-if="isActivePhotoDrugTableHead"
      @getList="getList"
    >
    </shiftManagementTableHead>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import {
  getSchedusList,
  getSchedusDetail,
  deleteSchedus,
} from "@/api/rowWorks/index";
import { getAdminFieldIndex } from "@/api/drugs2.0";
import shiftManagementTableHead from "@/views/workManagement/components/shiftManagementTableHead";
import elDrawerAdd from "@/views/workManagement/components/elDrawer";
import { mapState } from "vuex";
export default {
  name: "shiftManagement",
  // 在用客户
  components: {
    Pagination,
    shiftManagementTableHead,
    elDrawerAdd,
  },
  computed: {
    ...mapState({
      table_options: (state) => state.user.table_options,
    }),
  },
  async created() {
    console.log(this.$route.path);
    await this._getSchedusList();
    this.getList();
    setTimeout(()=>{
      console.log('this.getList();',this.operateList)
    },3000)
  },
  mounted() {
    this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      this.operateList = res;
    });
  },
  data() {
    return {
      operateList: [],
      searchTableHead: [
        {
          name: "id",
          isSearch: false,
        },
        {
          name: "title",
          isSearch: false,
        },
        {
          name: "time_range",
          isSearch: false,
        },
      ],
      tableHeadSortable: [
        "created_at",
        "expired_at",
        "next_visit_at",
        "end_visit_at",
        "end_order_at",
      ],
      isActivePhotoDrugTableHead: false,
      tableHead: [],
      choiceDateCreateRecord: [],
      choiceDateExpire: [],
      choiceDateEndVisit: [],
      choiceDateEndOrder: [],
      choiceDateNextVisit: [],
      searchParams: {
        keywords: "",
      },
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      total: 0,
      listLoading: false,
      tableData: [],
      drawerVisiblePresDetail: false,
      drawerVisible: false,
      info: {
        a: 1,
      },
      multipleSelection: [],
      multipleSelectionPres: [],
      curButtonShow: "",
    };
  },
  methods: {
    //编辑班次
    async handleEdit() {
      let id = this.multipleSelection[0];
      let res = await getSchedusDetail(id);
      if (res.code == 200) {
        this.info = res.data;
        this.info.edit = "edit";
        this.drawerVisible = true;
      }
    },
    //删除班次
    handleDel() {
      let id = this.multipleSelection[0];
      //提示是否删除
      this.$confirm("此操作将永久删除该班次, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteSchedus(id).then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getList();
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleClosePres() {
      this.drawerVisible = false;
      this.drawerVisiblePresDetail = false;
    },
    //新增班次
    handleAdd() {
      console.log("新增班次");
      this.drawerVisible = true;
      this.info.edit = "add";
    },
    getList(type) {
      console.log("getList触发");
      if (type == "restPage") {
        this.listQuery.page = 1;
      }
      console.log("getList触发");
      this.listLoading = true;
      this.searchParams.page = this.listQuery.page;
      this.searchParams.page_size = this.listQuery.limit;
      getSchedusList(this.searchParams)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.total = _data.total;
            this.tableData = _data.list;
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    }, // 如果draw数据更新,回调getList()刷新数据

    handleSearch(prop, value) {
      this.searchTableHead.forEach((item) => {
        if (item.name == prop) {
          if (value == "") {
            item.isSearch = false;
          } else {
            item.isSearch = true;
          }
        }
      });
      this.getList("restPage");
    },
    handleResetSearch() {
      this.searchParams = {
        type: "use",
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },
    handleEditTableHead() {
      this.isActivePhotoDrugTableHead = true;
    },
    async _getSchedusList() {
      // try {
      //   this.listLoading = true;
      //   var params = {
      //     type: "shiftManagement",
      //   };
      //   const res = await getAdminFieldIndex(params);
        this.tableHead = this.table_options.shiftManagement;
      //   console.log(this.tableHead);
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false;
      // }
    },

    handleSelectionChange(val) {
      console.log(val, "val");
      this.multipleSelection = [];
      this.multipleSelectionPres = [];
      val.forEach((item) => {
        this.multipleSelection.push(item.id);
        this.multipleSelectionPres.push(item);
      });
      console.log(
        this.multipleSelectionPres,
        this.multipleSelection,
        "========================"
      );
      if (this.multipleSelectionPres.length == 1) {
        this.curButtonShow = this.multipleSelectionPres[0].pres_status_name;
        console.log(this.curButtonShow, "========================");
      } else {
        this.curButtonShow = "";
      }
      //将this.multipleSelection传给子组件
      // console.log(this.$refs);
      // this.$refs.CDMSelectionOfTheService.multipleSelection = this.multipleSelection;
    },
  },
};
</script>

<style lang="scss" scoped>
.isSearch {
  color: #f56c6c;
}

.search-icon {
  color: #f56c6c;
}

/* switch按钮样式 */
::v-deep.switch {
  .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  /*打开时文字位置设置*/
  .el-switch__label--right {
    z-index: 1;
  }

  /* 调整打开时文字的显示位子 */
  .el-switch__label--right span {
    margin-right: 11px;
  }

  /*关闭时文字位置设置*/
  .el-switch__label--left {
    z-index: 1;
  }

  /* 调整关闭时文字的显示位子 */
  .el-switch__label--left span {
    margin-left: 11px;
  }

  /*显示文字*/
  .el-switch__label.is-active {
    display: block;
  }

  /* 调整按钮的宽度 */
  .el-switch__core,
  .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}

/*抽屉高度*/
/deep/ .el-drawer {
  bottom: 0 !important;
}

/deep/ .el-drawer__header {
  padding: 0;
  margin: 0;
}

/deep/ .el-drawer.rtl {
  overflow: visible;
  height: 100vh;
  bottom: 0;
  margin-top: 0vh;
  position: absolute;
}
</style>
